import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReportController from "../../components/ReportController";
import { useAuth } from "../../contexts/AuthContext";
import "./FullReport.css";

const FullReport = () => {
  const { setHeaderState, reports } = useAuth();
  const [report, setReport] = useState(null);
  const { id } = useParams();
  const { tableau } = window;
  const ref = useRef(null);
  const vizRef = useRef(null);

  function initViz(link) {
    if (ref.current && !vizRef.current) {
      const options = {
        width: "1200px",
        height: "800px",
      };
      vizRef.current = new tableau.Viz(ref.current, link, options);
    }
  }

  useEffect(() => {
    const report = reports.find((report) => report.id.toString() === id);
    setReport(report);

    if (report) {
      initViz(report.link);
      setHeaderState({
        title: "Report: " + report.title,
        favoritesIcon: true,
        calendarComponent: true,
        dateRange: "Last Month",
      });
    }

    // Cleanup function to remove the viz when the component unmounts or before initializing a new one
    return () => {
      if (vizRef.current) {
        vizRef.current.dispose();
        vizRef.current = null;
      }
    };
  }, [setHeaderState, id, reports]); // Include reports in dependencies to handle updates

  return (
    <div className="flex flex-col h-screen py-6 px-2 gap-4">
      <ReportController />
      {report ? (
        <>
          <div>
            <p>URL: {report.title}</p>
          </div>
          <div ref={ref} className="w-full tableau-viz-container"></div>
        </>
      ) : (
        <p>Report not found</p>
      )}
    </div>
  );
};

export default FullReport;
