import { useContext, createContext, useState, useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ email: "t.sokac89@gmail.com" });
  const [favorites, setFavorites] = useState([]);
  const { value, setItem, getItem, removeItem } = useLocalStorage();
  const [reports, setReports] = useState(() => {
    const savedReports = getItem("tableauReports"); // Use correct key
    return savedReports ? JSON.parse(savedReports) : [];
  });
  const [headerState, setHeaderState] = useState({
    title: "Curator",
    favoritesIcon: false,
    calendarComponent: false,
  });

  useEffect(() => {
    setItem("tableauReports", JSON.stringify(reports));
  }, [reports, setItem]);

  return (
    <AuthContext.Provider
      value={{
        setItem,
        getItem,
        user,
        headerState,
        setHeaderState,
        favorites,
        setFavorites,
        reports,
        setReports,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
